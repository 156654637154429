import { useRef, useState, useEffect } from "react";
import { useHydrated } from "remix-utils/use-hydrated";
import debounce from "lodash/debounce";

import { ScreenWidth } from "constants/styles";

import { WindowSize } from "./types";

const useResizeWindow = ({ debounceDelay = 150 } = {}): [
  WindowSize,
  WindowSize | null,
] => {
  const isHydrated = useHydrated();
  const prevSizesRef = useRef<WindowSize | null>(null);
  const [sizes, setSizes] = useState<WindowSize>(() => {
    if (isHydrated) {
      return {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    }

    return {
      width: ScreenWidth.desktopSmall,
      height: 0,
    };
  });

  useEffect(() => {
    if (!isHydrated) {
      setSizes({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    const handler = debounce(({ target }) => {
      prevSizesRef.current = sizes;
      setSizes({ width: target.innerWidth, height: target.innerHeight });
    }, debounceDelay);

    window.addEventListener("resize", handler);

    return () => window.removeEventListener("resize", handler);
  }, []);

  return [sizes, prevSizesRef.current];
};

export default useResizeWindow;
