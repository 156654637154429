import { PropsWithChildren } from "react";
import joinClassNames from "classnames";

import EmptyFolder from "icons/empty-folder.svg?react";

import classes from "./styles/classes.module.scss";

interface Props extends PropsWithChildren {
  title?: string;
  subtitle?: string;
  className?: string;
  classNames?: Partial<{ title: string; description: string }>;
}

const EmptyScreen = ({
  title = "Nothing found",
  subtitle = "For some reason we have nothing that should be in this section",
  children,
  className,
  classNames = {},
}: Props) => (
  <div className={joinClassNames(classes.wrapper, className)}>
    <EmptyFolder />
    <div>
      {title && (
        <h2 className={joinClassNames(classes.title, classNames.title)}>
          {title}
        </h2>
      )}
      {subtitle && (
        <p
          className={joinClassNames(
            classes.description,
            classNames.description,
          )}
        >
          {subtitle}
        </p>
      )}
    </div>
    {children}
  </div>
);

export default EmptyScreen;
